<template>
    <div>
        <v-form
                ref="form"
                v-model="valid"
        >
                <span class="input-level"><i class="far fa-fw fa-star text-danger"></i> Mobile Number:</span>
                <v-text-field
                        v-model="mobile_number"
                        outlined
                        dense
                        :rules="[ v => !!v || 'Mobile Number is required', v => (v && v.length === 11)  || 'Phone number must be 11 digits',]"
                        required
                ></v-text-field>
            <div id="mobile-verify" v-if="otpSent">
                    <span class="input-level"><i class="far fa-fw fa-star text-danger"></i> OTP Code:</span>
                    <v-text-field
                            v-model="otp"
                            dense
                            outlined
                            :rules="[ v => !!v || 'OTP is required', v => (v && v.length == 6)  || 'OTP must be 6 digits',]"
                            required
                    ></v-text-field>
            </div>
            <div class="row">
                <div class="col-md-4 offset-md-4">
                    <button class="btn btn-primary rounded-pill btn-block" v-if="!otpSent" :disabled="!valid"  @click="sendOtp">
                        Send OTP
                    </button>
                    <button class="btn btn-primary rounded-pill btn-block" v-else :disabled="!valid" @click="loginWithOtp">
                        Verify OTP
                    </button>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
    import authService from "@/core/services/auth/auth.service";
    import store from "@/core/services/store";
    import {ACTION_SET_TOKEN} from "@/core/services/store/authentication.module";

    export default {
        name: "LoginWithOtp",
        data(){
            return {
                valid : false,
                mobile_number: null,
                otp: null,
                otpSent : false
            }
        },
        methods : {
            loginWithOtp(e){
                // console.log(this.$route); return;
                e.preventDefault();
                authService.loginWithOtp({
                    mobile_number: this.mobile_number,
                    otp: this.otp
                }, (responseData) => {
                    store.dispatch(ACTION_SET_TOKEN,responseData).then(()=>{
                            this.$router.push({path:"/profile"},()=>{
                                this.$toasted.success("Login successful");
                            });
                        }
                    )
                }, error => {
                    this.$toasted.error(error);
                })
            },
            sendOtp(e){
                e.preventDefault();
                this.otpSent = true;
                authService.requestForOtp({mobile_number:this.mobile_number},(data)=>{
                    this.otpSent = true;
                    this.$toasted.success(data.message);
                },(error)=>{
                    if(error.includes("Recently an OTP has been sent")){
                      this.otpSent = true;
                      this.$toasted.success(error);
                    }else{
                      this.otpSent = false;
                      this.$toasted.error(error);
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
