<template>
    <div>
        <v-form @submit.prevent
                ref="form"
                v-model="valid"
        >
            <span class="input-level"><i class="far fa-fw fa-star text-danger"></i> Mobile Number:</span>
            <v-text-field
                    v-model="mobile_number"
                    dense
                    outlined
                    :rules="[ v => !!v || 'Mobile Number is required', v => (v && v.length === 11)  || 'Phone number must be 11 digits',]"
                    required
            ></v-text-field>
            <span class="input-level"><i class="far fa-fw fa-star text-danger"></i> Password:</span>
            <v-text-field
                    v-model="password"
                    type="password"
                    outlined
                    dense
            ></v-text-field>
            <div class="row">
                <div class="col-md-4 offset-md-4">
                    <button class="btn btn-primary rounded-pill btn-block" :disabled="!valid" @click="loginWithPassword">
                        Sign In
                    </button>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
    import authService from "@/core/services/auth/auth.service";
    import store from "@/core/services/store";
    import {ACTION_SET_TOKEN} from "@/core/services/store/authentication.module";

    export default {
        name: "LoginWithPassword",
        data(){
          return {
              mobile_number: null,
              password: null,
              valid : false,
          }
        },
        methods : {
            loginWithPassword(){
                authService.loginWithPassword({
                        mobile_number:this.mobile_number,
                        password:this.password
                    }, (responseData) => {
                        store.dispatch(ACTION_SET_TOKEN,responseData).then(()=>{
                                this.$router.push({path:"/profile"},()=>{
                                    this.$toasted.success("Login successful");
                                });
                            }
                        )
                    }, error => {
                        this.$toasted.error(error);
                    })
            },
        },

    }
</script>

