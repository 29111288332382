<template>
    <div class="d-flex align-items-center justify-content-between py-10 container" >
        <div class="col-md-12 p-0">
            <div class="card card-custom">
                <div class="card-header">
                    <div class="card-title">
                        <span class="card-icon">
                            <i class="far fa-user-circle text-aqua"></i>
                        </span>
                        <h1 class="card-label text-uppercase">Passenger Sign In</h1>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-4 offset-sm-4">
                            <div class="form-group">
                                <h3 style="margin:20px 0px 20px 0px; text-align:center; color:#EC1C24;">Already have an account?</h3>
                                <h4 style="text-align: center; margin-bottom: 30px;">Then sign in to your account.</h4>
                            </div>
                            <div class="row">
                                <div class="form-group col-md-6" style="text-align: center;" @click="setLoginMethod('password')">
                                    <div id="sibtn1"
                                         v-bind:class="{'bg-aqua': selectedLoginMethod =='password'}"
                                         style="padding: 6px 10px 6px 10px; margin: 0px; border: 1px solid #ccc; cursor: pointer;">
                                        Sign In by Password
                                    </div>
                                </div>
                                <div class="form-group col-md-6" style="text-align: center;" @click="setLoginMethod('otp')">
                                    <div id="sibtn2" class="sibtn"
                                         v-bind:class="{'bg-aqua': selectedLoginMethod =='otp'}"
                                         style="padding: 6px 10px 6px 10px; margin: 0px; border: 1px solid #ccc; cursor: pointer;">
                                        Sign In by OTP
                                    </div>
                                </div>
                            </div>
                            <template v-if="selectedLoginMethod == 'password'">
                                <Login-With-Password></Login-With-Password>
                            </template>
                            <template v-else>
                                <Login-With-Otp></Login-With-Otp>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .spinner.spinner-right {
        padding-right: 3.5rem !important;
    }
    .bg-green{
        background-color: #20b887 !important;
        color:white;
    }
</style>

<script>
    import {mapState} from "vuex";
    import {LOGIN, LOGOUT} from "@/core/services/store/auth.module";
    import {AUTH_LOGOUT} from "@/core/services/store/authentication.module";
    import {validationMixin} from "vuelidate";
    import {email, minLength, required} from "vuelidate/lib/validators";

    import authService from '@/core/services/auth/auth.service';
    import LoginWithPassword from '@/view/pages/auth/LoginWithPassword';
    import LoginWithOtp from '@/view/pages/auth/LoginWithOtp';

    export default {
        mixins: [validationMixin],
        name: "login",
        components: {
            LoginWithPassword,
            LoginWithOtp
        },
        data() {
            return {
                selectedLoginMethod: 'password',
                mobileNumber: null,
                password: null,
            };
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(3)
                }
            }
        },
        mounted(){
          this.$store.dispatch(AUTH_LOGOUT);
        },
        methods: {
            callTestApi(){
                authService.loginWithPassword()
            },
            setLoginMethod(method){
                this.selectedLoginMethod = method;
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                const email = this.$v.form.email.$model;
                const password = this.$v.form.password.$model;

                // clear existing errors
                this.$store.dispatch(LOGOUT);

                // set spinner to submit button
                const submitButton = this.$refs["kt_login_signin_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                // dummy delay
                setTimeout(() => {
                    // send login request
                    this.$store
                        .dispatch(LOGIN, {email, password})
                        // go to which page after successfully login
                        .then(() => this.$router.push({name: "dashboard"}));

                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                }, 2000);
            }
        },
        computed: {
            ...mapState({
                errors: state => state.auth.errors
            })
        }
    };
</script>
