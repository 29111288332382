// import ApiService from '@/core/services/api.service';
import axios from 'axios';
import loginUrls from '@/core/services/constants/LoginUrls';

const authService = {
    loginWithPassword(data,success,error){
        axios.post(loginUrls.loginWithPassword, data)
            .then(responseData => success(responseData.data))
            .catch( errorResponse =>{
                error(errorResponse.response.data.message);
            })
    },
    requestForOtp(phoneNumber,success,error){
        axios.post(loginUrls.requestOtp,phoneNumber)
            .then(responseData => success(responseData.data))
            .catch( e  => {
                error(e.response.data.message);
            })
    },
    loginWithOtp(data,success,error){
        axios.post(loginUrls.loginWithOtp, data)
            .then(responseData => success(responseData.data))
            .catch( e  => {
                error(e.response.data.message);
            })
    },
};

export default authService;





